import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { MuiSxCollection } from '../../../types';
import { CloseOutlined } from '@mui/icons-material';
import { trackEvent } from '@/utils/analytics';
import { RiSegmentEvents } from '../../../constants';
import { resetPageSearchParam } from '@/utils/purge-search-params.util';

type SearchFormType = {
  search: string | null;
};

type Props = {
  label?: string;
};

const KEY = 'search';

const sxProps = {
  root: {
    backgroundColor: '#fff !important',
    ['&:focus, &:hover']: {
      backgroundColor: '#fff !important',
      border: (theme) => `1px solid ${theme.palette.border.primary} !important`,
    },
    border: (theme) => `1px solid ${theme.palette.border.primary}`,
    '&:before': {
      borderBottom: 'none !important',
    },
  },
} satisfies MuiSxCollection;

export function TableSearchField({ label }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const form = useForm<SearchFormType>({
    defaultValues: {
      search: searchParams.get(KEY) ?? '',
    },
  });

  const handleFormSubmit = (values: SearchFormType, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();

    trackEvent(RiSegmentEvents.RiApplicationTableSearchBarChange, {
      searchValue: values.search,
    });

    setSearchParams((prevSearchParams) => {
      resetPageSearchParam(prevSearchParams);

      if (!values.search) {
        prevSearchParams.delete(KEY);

        return prevSearchParams;
      }

      prevSearchParams.set(KEY, values.search);

      return prevSearchParams;
    });
  };

  const handleSearchClear = () => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.delete(KEY);
      resetPageSearchParam(prevSearchParams);

      return prevSearchParams;
    });

    form.reset({
      search: '',
    });
  };

  return (
    <form onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Controller
        name="search"
        control={form.control}
        data-testid="table-search"
        render={({ field }) => (
          <TextField
            className="fs-mask"
            value={field.value}
            onChange={field.onChange}
            label={label}
            fullWidth={true}
            sx={{ width: '300px' }}
            InputProps={{
              sx: sxProps.root,
              endAdornment: field.value && (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="table-search-clear-btn"
                    aria-label="clear search text"
                    onClick={handleSearchClear}
                    edge="end"
                  >
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </form>
  );
}
